import Cookies from "js-cookie";
import { Dialog, Toast } from "vant";
import router from "../router";

const judgStatus = (res) => {
  if (res.code == 200) {
    // 成功
    return res;
  } else if (res.code == 201) {
    // 失败
    Toast(res.msg);
    return false;
  } else if (res.code == 202) {
    // 登录过期
    Cookies.remove("token");
    // console.log('跳转到登录');
    router.push({ path: "/login", replace: true });
    return false;
  } else if (res.code == 204) {
    // 没权限
    Dialog.alert({
      title: "权限不足",
      message: res.msg + "，请联系管理员",
    }).then(() => {});
    return false;
  }
};
export default judgStatus;
