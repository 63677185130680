import Cookies from "js-cookie";
import config from "../config";

export const TOKEN_KEY = "token";
export const PS_KEY = "permissions";
let token = null;
export const setToken = (tk) => {
  if (tk) {
    Cookies.set(TOKEN_KEY, tk, {
      expires:
        new Date(
          new Date().getTime() + config.cookieExpires * 60 * 1000 * 24
        ) || 1,
    });
    token = tk;
  } else {
    token = "";
    Cookies.set(TOKEN_KEY, "", -1);
  }
};

export const getToken = () => {
  if (token) {
    return token;
  } else {
    token = Cookies.get(TOKEN_KEY);
    if (token) return token;
    else return false;
  }
};

let permissions = null;
export const setPermissions = (ps) => {
  if (ps) {
    // console.log(ps);
    Cookies.set(PS_KEY, ps, {
      expires:
        new Date(
          new Date().getTime() + config.cookieExpires * 60 * 1000 * 24
        ) || 1,
    });
    permissions = ps;
  } else {
    permissions = null;
    Cookies.set(PS_KEY, "", -1);
  }
};

export const setCampusId = (ps)=>{
  if (ps) {
    // console.log(ps);
    Cookies.set('campus_id', ps, {
      expires:
        new Date(
          new Date().getTime() + config.cookieExpires * 60 * 1000 * 24
        ) || 1,
    });
  } else {
    Cookies.set('campus_id', "", -1);
  }
}

export const setCampusName = (ps)=>{
  if (ps) {
    // console.log(ps);
    Cookies.set('campus_name', ps, {
      expires:
        new Date(
          new Date().getTime() + config.cookieExpires * 60 * 1000 * 24
        ) || 1,
    });
  } else {
    Cookies.set('campus_name', "", -1);
  }
}

export const getPermissions = () => {
  if (permissions) {
    return permissions;
  } else {
    permissions = Cookies.get(PS_KEY);
    if (permissions) return permissions;
    else return false;
  }
};
// Generate four random hex digits.
export const S4 = () => {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};

// Generate a pseudo-GUID by concatenating random hexadecimal.
export const guid = () => {
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};
