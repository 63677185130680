import http from '../lib/http'
const RESTURL ='/back/login'
const OUTREST = 'back/loginOut'
const API = {
    login(data) {
        return new Promise((resolve, reject) => {
            http.post(RESTURL, data).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    loginOut() {
        return new Promise((resolve, reject) => {
            http.post(OUTREST).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
}
export default API
