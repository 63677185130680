let baseWebURL = ''
// 环境的切换
if (process.env.NODE_ENV === 'development') { //开发环境
    baseWebURL = ''
} else if (process.env.NODE_ENV === 'test') { //测试环境
    baseWebURL = ''
} else if (process.env.NODE_ENV === 'production') { //生产环境
    baseWebURL = ''
}
const cfg = {
    timeout: 10000,
    /**
     * @description api请求基础路径
     */
    /**
  * @description token在Cookie中存储的天数，默认1天
  */
    cookieExpires: 60 * 15,
    /**
 * @description 默认打开的首页的路由name值，默认为home
 */
    homeName: 'home',
    loginName: 'Login',
    baseUrl: {
        dev: process.env.VUE_APP_SERVER_URL,
        test: process.env.VUE_APP_SERVER_URL,
        // 修改成读取环境变量，环境变量为空则默认为localhost:3333,在vue.config.js注入
        // pro: "https://edu.ysbus.com:3005",
        pro: process.env.VUE_APP_SERVER_URL,
    },

    baseWebURL: baseWebURL,
}
export default cfg