<template>
  <!-- <div :style="{ 'margin-top': '-40px', transform: 'translateY(' + top + 'px)' }">
    <div
      class="loading-box"
      v-if="!isRefresh"
      :style="{
        color: '#969799',
        'font-size': '14px',
        margin: '10px 0',
      }"
    >
      <van-icon
        name="down"
        :style="{
          'margin-right': '10px',
          transform: flag ? 'rotate(180deg)' : '',
        }"
      />
      <span>{{ loadingText }}</span>
    </div>
    <van-loading v-else style="padding: 15px 0">{{ loadingText }}</van-loading>
    <div
      class="home-box"
      @touchstart="touchStart($event)"
      @touchmove="touchMove($event)"
      @touchend="touchEnd($event, getDelivery, resetQueryParams)"
    > -->
        <div
      class="home-box"
    >
      <van-button to="/dist-order" size="large" class="btn" type="success" block round
        >订单查看</van-button
      >
      <van-button to="/statis" size="large" class="btn" type="success" block round
        >订单统计</van-button
      >
      <van-button to="/admin" size="large" class="btn" type="success" block round
        >超级管理员入口</van-button
      >
      <van-button @click="loginOut()" class="btn" size="large" type="danger" block round
        >退出登录</van-button
      >
    </div>
    <van-popup v-model:show="show" position="bottom" :style="{ height: '47%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmDate"
        @cancel="show = false"
      />
    </van-popup>
  <!-- </div> -->
</template>
<script>
import { reactive, ref } from "vue";
import { 
  Button, 
  Dialog, 
  Popup, 
  DatetimePicker, 
  // Icon, 
  // Loading
  } from "vant";
import Cookies from "js-cookie";
import { useRouter } from "vue-router";
import api from "../../api/deliveryData";
import httpLoginOut from "../../api/login";
import { Toast } from "vant";
import refresh from "../../state/refresh-data";
export default {
  name: "Home",
  components: {
    "van-button": Button,
    "van-popup": Popup,
    "van-datetime-picker": DatetimePicker,
    // "van-icon": Icon,
    // "van-loading": Loading,
  },
  setup() {
    // 获取token
    const router = useRouter();
    const activeNames = ref(["1"]);
    // 配送信息
    const info = ref({});
    function navigationTo(path) {
      router.push({ path });
    }
    const confirmCurrentDate = ref(
      `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
    );
    const query = reactive({
      start_time: confirmCurrentDate.value,
    });
    const getDelivery = async () => {
      try {
        const res = await api.getDeliveryData(query);
        if (res) {
          info.value = res.data;
          flag.value = false;
          isRefresh.value = false;
          loadingText.value = "下拉刷新";
          top.value = 0;
        }
      } catch (error) {
        Toast("获取配送信息出错");
      }
    };

    const currentDate = ref(new Date());
    const show = ref(false);
    const showPopup = () => {
      show.value = true;
    };
    const confirmDate = (date) => {
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();
      const formatDate = y + "-" + m + "-" + d;
      confirmCurrentDate.value = formatDate;
      query.start_time = formatDate;
      getDelivery();
      show.value = false;
    };
    // 退出登录
    const loginOut = () => {
      Dialog.confirm({
        title: "退出提示",
        message: "是否退出当前账号",
      })
        .then(async () => {
          try {
            const res = await httpLoginOut.loginOut();
            if (res) {
              Cookies.remove("token");
              Cookies.remove("permissions");
              router.push({ path: "login", replace: true });
            }
          } catch (error) {
            Toast("退出登录出错");
          }
        })
        .catch(() => {
          // on cancel
        });
    };

    const resetQueryParams = () => {
      confirmCurrentDate.value = `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`;
      query.start_time = confirmCurrentDate.value;
    };

    // 获取配送信息
    getDelivery();

    // 刷新
    const {
      flag,
      isRefresh,
      loadingText,
      top,
      touchStart,
      touchMove,
      touchEnd,
    } = refresh();

    return {
      activeNames,
      info,
      show,
      currentDate,
      confirmCurrentDate,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      flag,
      isRefresh,
      loadingText,
      top,

      touchStart,
      touchMove,
      touchEnd,
      showPopup,
      navigationTo,
      loginOut,
      confirmDate,
      getDelivery,
      resetQueryParams,
    };
  },
};
</script>
<style scoped lang="less">
.home-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 50px;
  // justify-content: center;
  align-items: center;
}
.btn {
  margin: 20px 0;
  // border-radius: 8px;
  font-size: 40px;
}
.info {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  // padding: 0 50px;
  text-align: left;
  box-sizing: border-box;
}
.van-collapse-item {
  border: 2px solid skyblue;
  // border-top: none;
}
.nf,
.jt {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.today {
  margin-bottom: 60px;
}
.tomorrow,
.today {
  border: 2px solid #ccc;
  border-radius: 30px;
  box-sizing: border-box;
  .dist-data {
    padding: 20px 100px;
    .wz-data {
      display: flex;
      justify-content: space-between;
      padding-bottom: 40px;
      border-bottom: 2px solid #ccc;
    }
    .cs-data {
      padding-top: 40px;
      display: flex;
      justify-content: space-between;
    }
  }
}
// 修改字体大小
::v-deep .van-cell {
  font-size: 40px;
}
::v-deep .van-card {
  font-size: 36px;
}
dd {
  font-size: 28px;
  margin-left: 40px;
}
dl,
dt {
  margin: 0;
  padding: 0;
}
dl {
  padding: 20px 40px;
  border-bottom: 2px solid #ccc;
}
</style>
