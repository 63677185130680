import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Lazyload } from 'vant';
// vant 的样式
import 'vant/lib/index.css';
import '@vant/touch-emulator';

import './main.css'

createApp(App).use(router).use(Lazyload).mount('#app')
