import { ref } from "vue";

export default function() {
  // 下拉刷新
  const flag = ref(false);
  const isRefresh = ref(false);
  const loadingText = ref("下拉刷新");
  const top = ref(0);
  // 首次点击时手指位置
  const startY = ref(0);
  const endY = ref(0);
  const touchStart = (e) => {
    startY.value = e.targetTouches[0].pageY;
  };

  // 手指移动
  const touchMove = (e) => {
    const yy = e.changedTouches[0].pageY - startY.value;
    top.value = Math.floor(yy);
    if (e.changedTouches[0].pageY - startY.value < 240) {
      // console.log(123);
      flag.value = false;
      isRefresh.value = false;
      loadingText.value = "下拉刷新";
      // top.value = 0;
      return;
    }
    if (yy > 240) {
      flag.value = true;
      loadingText.value = "松开刷新";
    }
  };

  // 松开手指
  const touchEnd = (e, refreshFunc, paramsFunc) => {
    endY.value = e.changedTouches[0].pageY;
    if (endY.value - startY.value < 240) {
      top.value = 0;
      return;
    }
    if (endY.value - startY.value > 240) {
      isRefresh.value = true;
      loadingText.value = "刷新中...";
      paramsFunc ? paramsFunc() : "";
      refreshFunc();
    } else {
      flag.value = false;
      isRefresh.value = false;
      loadingText.value = "下拉刷新";
      top.value = 0;
    }
  };
  return {
    flag,
    isRefresh,
    loadingText,
    top,
    startY,
    endY,
    touchStart,
    touchMove,
    touchEnd,
  };
}
