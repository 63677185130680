import axios from 'axios'
import QS from 'qs' //视情况用于不用;
import config from '../config'
import {setToken,getToken} from './unit'
function startLoading() { //使用Element loading-start 方法
    /*
        loading = Loading.service({
            lock: true,
            text: '努力加载中……',
            background: 'rgba(0, 0, 0, 0.5)'
        })
    */
}

function endLoading() { //使用Element loading-close 方法
    // loading.close()
}


//那么 showFullScreenLoading() tryHideFullScreenLoading() 要干的事儿就是将同一时刻的请求合并。
//声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
//调用tryHideFullScreenLoading()方法，needLoadingRequestCount - 1。needLoadingRequestCount为 0 时，结束 loading。
let needLoadingRequestCount = 0

export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}

const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro

/**
 *
 */
class HttpReq {
    /**
     *
     * @param baseUrl
     * @param keepAuth //保持登录
     * @param tokenFunc  //获取token的函数
     * @param contentType //请求提交的类型
     */
    constructor({
        baseUrl = baseUrl,
        keepAuth,
        tokenFunc = () => {
            return getToken()
        },
        contentType = 'application/json;charset=UTF-8'
    }) {
        this.baseUrl = baseUrl
        this.queue = {}
        this.send = false
        this.keep = keepAuth
        this.tokenFunc = tokenFunc
        this.contentType = contentType
        let instance = axios.create({
            baseURL: this.baseUrl,
        })
        this.instance = instance

        //1.请求超时时间
        instance.defaults.timeout = config.timeout
        //2.post请求头
        //         instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
        //3.公共部分(请求带token设置)
        // instance.defaults.headers.common['Authorization'] = Store.state.token
        //4.返回数据类型的定义
        instance.defaults.responseType = 'json'
        //5.带cookie请求
        instance.defaults.withCredentials = false

        // 请求拦截器
        instance.interceptors.request.use(
            config => {
                // token 由 tokenFunc提供，
                // 如果tokenFunc返回的非null或者非空字符串
                // 就把token放入header中
                const token = this.tokenFunc()
                token && (config.headers.Authorization = token);
                if (config.method === 'post') {
                    // console.log('post请求统一需要做什么判断')
                    // config.headers['Content-Type'] = 'multipart/form-data'
                }
                config.headers['Refresh-Token'] = true
                config.headers['token'] = token
                config.headers.Accept = this.contentType; //规定接受形式json格式
                showFullScreenLoading() //开启loading
                return config;
            }, error => {
                return Promise.reject(error)
            })

        // 响应拦截器
        instance.interceptors.response.use(
            response => {
                // console.log(response, 'response响应拦截器')
                // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
                //否则的话抛出错误
                if (response.status === 200) {
                    // tryHideFullScreenLoading() //关闭loading
                    let Refresh_Token = response.config.headers['Refresh-Token']
                    if (Refresh_Token && response.headers['set-token']) {
                        setToken(response.headers['set-token'])
                    }
                    return Promise.resolve(response)
                } else {
                    return Promise.reject(response)
                }
            }, error => {
                console.log(error, 'error')

                return Promise.reject(error);
            })

    }

    get(url, params, config) {
        return new Promise((resolve, reject) => {
            if (params) {
                url = url + '?' + QS.stringify(params)
            }
            this.instance.get(url, config).then(response => {
                resolve(response.data);
            })
                .catch(error => {
                    reject(error);
                });
        });
    }

    post(url, data, config) {
        return new Promise((resolve, reject) => {
            this.instance.post(url, data, config).then(response => {
                resolve(response.data);
            })
                .catch(error => {
                    reject(error);
                });
        });
    }

    put(url, data, config) {
        return new Promise((resolve, reject) => {
            this.instance.put(url, data, config).then(response => {
                resolve(response.data);
            })
                .catch(error => {
                    reject(error);
                });
        });
    }

    delete(url, params, config) {
        return new Promise((resolve, reject) => {
            if (params) {
                url = url + '/' + encodeURIComponent(params.id)
            }
            this.instance.delete(url, config).then(response => {
                resolve(response.data);
            })
                .catch(error => {
                    reject(error);
                });
        });
    }

}

const http = new HttpReq({ baseUrl })

export default http

