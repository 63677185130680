import { createRouter, createWebHashHistory } from 'vue-router'
import { getPermissions, getToken } from '../lib/unit'
import routes from './routes'
import config from '../config/index'
import { Dialog } from 'vant'
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  let token = getToken()
  let ps = getPermissions()
  if (to.name === config.loginName) {
    next()
  } else if (token === false) {
    // 检查是否登录，如果未登录
    next({ name: config.loginName })
  } else if (to.name === 'StaffList' || to.name === 'Role' || to.name === 'AuthManagement' || to.name === 'SystemSettings') {
    if (ps.indexOf(',') == -1) {
      const authArr = [ps]
      if (authArr[0] == 'all') {
        next()
      } else {
        Dialog.alert({
          title: '权限不足',
          message: '您还没有权限，请联系超级管理员',
        }).then(() => {

        });
      }
    } else {
      const authArr = ps.split(',')
      if (authArr.indexOf('all') != -1) {
        next()
      } else {
        Dialog.alert({
          title: '权限不足',
          message: '您还没有权限，请联系超级管理员',
        }).then(() => {

        });
      }
    }
  } else {
    // const auth = to.meta
    // for (const key in auth) {
    //   if (Object.hasOwnProperty.call(auth, key)) {
    //     const el = auth[key];
    //     console.log(ps.indexOf(el) != -1);
    //     if (ps.indexOf(el) != -1) {
    //       console.log(789);
    //       next()
    //     } else {
    //       console.log(1);
    //       Dialog.alert({
    //         title: '权限不足',
    //         message: '您还没有权限，请联系超级管理员',
    //       }).then(() => {
    //         console.log(to);
    //         router.go(-1)

    //       });
    //     }
    //   }
    // }
    next()
  }
})
export default router