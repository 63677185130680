import Home from '../views/home/home.vue'

export default [
    {
        // 访问根路径重定向到/home
        path: '/',
        redirect: '/home'
    },
    // home界面
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    // 登录
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/login.vue')
    },
    // 订单统计
    {
        path: '/statis',
        name: 'Statis',
        redirect: '/statis/staff-statis',
        component: () => import('../views/statis/statis.vue'),
        children: [ {
            path: '/statis/staff-statis',
            name: 'StaffStatis',
            component: () => import('../views/statis/staff-statis/staff-statis.vue'),
        },{
            path: '/statis/data-statis',
            name: 'DateStatis',
            component: () => import('../views/statis/data-statis/data-statis.vue'),
        },]
    },
    {
        path: '/dist-order',
        name: 'DistOrder',
        component: () => import('../views/dist-order/order.vue'),
    },
    {
        path: '/admin',
        name: 'Admin',
        redirect: '/admin/staff-list',
        component: () => import('../views/admin/admin.vue'),
        children: [{
            path: '/admin/staff-list',
            name: 'StaffList',
            component: () => import('../views/admin/staff-list/staff-list.vue'),
        }, {
            path: '/admin/role',
            name: 'Role',
            component: () => import('../views/admin/role/role.vue')
        }, {
            path: '/admin/auth-management',
            name: 'AuthManagement',
            component: () => import('../views/admin/auth-management/auth-management.vue')
        }, {
            path: '/admin/system-settings',
            name: 'SystemSettings',
            component: () => import('../views/admin/system-settings/system-settings.vue')
        },]
    },
]